<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
        <Row>
          <Col span="8">
            <DatePicker    @on-change="startDateHandleChange" format="yyyy年MM月dd日" type="date" placeholder="开始时间" style="width: 200px"></DatePicker>
          </Col>
           <Col span="8">
            <DatePicker    @on-change="endDateHandleChange" format="yyyy年MM月dd日" type="date" placeholder="结束时间" style="width: 200px"></DatePicker>
          </Col>
          <Col span="8">
                 <Button type="primary"  class="add" @click="findReportList"  icon="md-search">查询</Button>
                  <Button type="primary"  class="search-bt" @click="exportExcel" icon="md-download">导出</Button>
          </Col>

        </Row>


    </div>
      <Table
        :max-height="maxHeight"
        border
        stripe
        :columns="columns"
        :data="reportList"
        show-summary
        size="small"
      ></Table>
      <!-- <br /> -->
      <!-- <Page
            :total="total"
            @on-change="changePage"
            @on-page-size-change="changePageNum"
            show-sizer
            show-elevator /> -->
    </div>

     <Modal
        v-model="updateModel"
        title="修改"
        :styles="{top: '0px'}"
        @on-ok="updateOk"
        @on-cancel="updateCancel">
          <Form :model="updateVo" :label-width="80">
               <FormItem label="机构">
                <Input v-model="updateVo.organization" placeholder="机构名称"></Input>
              </FormItem>
                <FormItem label="商业险">
                <Input  v-model="updateVo.sy" placeholder="商业险"></Input>
              </FormItem>
              <FormItem label="强制险">
                    <Input v-model="updateVo.jq" placeholder="强制险"></Input>
              </FormItem>
                <FormItem label="车船税">
                    <Input v-model="updateVo.ccs" placeholder="车船税"></Input>
              </FormItem>
                <FormItem label="驾意险">
                    <Input v-model="updateVo.jy" placeholder="驾意险"></Input>
              </FormItem>
               <FormItem label="总金额">
                    <Input v-model="updateVo.total" placeholder="总金额"></Input>
              </FormItem>
               <FormItem label="回款金额">
                    <Input v-model="updateVo.reMoney" placeholder="回款金额"></Input>
              </FormItem>
               <FormItem label="回款时间">
                    <DatePicker
                    @on-change="reDateHandleChange"
                    @on-clear="reTimeClear"
                    format="yyyy年MM月dd日"
                    v-model="updateVo.reTime"
                    type="date"
                    placeholder="选择回款时间"
                    style="width: 100%"></DatePicker>
              </FormItem>
               <FormItem label="余款未付">
                    <Input v-model="updateVo.noPay" placeholder="余款未付"></Input>
              </FormItem>
               <FormItem label="备注">
                    <Input v-model="updateVo.remark" placeholder="备注"></Input>
              </FormItem>
          </Form>
    </Modal>
  </div>
</template>

<script>
import { updateReport, delOrganizationVo, addOrganization, findReportList } from '@/api'

let moment = require('moment')
 // require
export default {
  name: 'Organization',
  data() {
    return {
      maxHeight: 800,
      pageIndex: 1,
      pageSize: 10000,
      pageNum: 10,
      total: 100,
      organizationName: '',
      reportList: [],
      businessList: [],
      dataList: [],
      startTime: 0,
      endTime: 0,
      updateModel: false,
      updateVo: {
      },
      delModel: false,
      delorId: '',
      addModel: false,
      addVo: {
        organizationName: '',
      },
      columns: [
           {
              type: 'index',
              width: 60,
              align: 'center',
            },
        {
          title: '机构名称',
          key: 'organization',
          resizable: true,
        },
        {
          title: '商业险',
          key: 'sy',
          resizable: true,
        },
         {
          title: '交强险',
          key: 'jq',
          resizable: true,
        },
         {
          title: '车船税',
          key: 'ccs',
          resizable: true,
        },
         {
          title: '驾意险',
          key: 'jy',
          resizable: true,
        },
         {
          title: '总金额',
          key: 'total',
          resizable: true,
        },
        {
          title: '回款金额',
          key: 'reMoney',
          resizable: true,
        },
        {
          title: '回款时间',
          key: 'reTime',
          resizable: true,
           render: (h, params) => h('div',
                moment(params.row.reTime === null ? 0 : params.row.reTime).format('yyyy年MM月DD日')),
        },
         {
          title: '余款未付',
          key: 'noPay',
          resizable: true,
        },
        {
          title: '备注',
          key: 'remark',
          resizable: true,
        },
        // {
        //   title: '创建时间',
        //   key: 'createTime',
        //   resizable: true,
        //    render: (h, params) => h('div',
        //         moment(params.row.createTime).format('yyyy年MM月DD日 hh:mm')),
        // },
        {
          title: '操作',
          key: 'action',
          width: 80,
          align: 'center',
          render: (h, params) => h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '修改'
              ),
            ]),
        },
      ],
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 280
    // this.findCzPriceList();
    // this.findCzList();
    // this.findThickList();
    this.findReportList()
    // this.findSalesBusinessList()
  },
created() {

  },
methods: {
      findReportList() {
            let data = 'pageSize=' + this.pageSize + '&pageIndex=' + this.pageIndex
            data += '&startTime=' + (this.startTime ? this.startTime : 0)

            this.endTime = this.endTime ? this.endTime : new Date().getTime()
            if (this.endTime === 0) {
               data += '&endTime=' + new Date().getTime()
            } else {
                data += '&endTime=' + this.endTime
            }
            console.log(data)

            findReportList(data).then(res => {
                this.reportList = res.data.list
                // this.total = res.data.count
                console.log(res.data)
            })
      },
      exportExcel() {
           let data = 'startTime=' + (this.startTime ? this.startTime : 0)
            this.endTime = this.endTime ? this.endTime : new Date().getTime()
            if (this.endTime === 0) {
               data += '&endTime=' + new Date().getTime()
            } else {
                data += '&endTime=' + this.endTime
            }
            // window.open('http://120.27.220.134:9090/exportReportExcel?' + data)
            window.open('http://p172n64725.51mypc.cn:8002/exportReportExcel?' + data)
      },
       changePageNum(size) {
              this.pageSize = size
              this.findReportList()
            },
            changePage(p) {
              this.pageIndex = p
              this.findReportList()
            },
            startDateHandleChange(date) {
                console.log(date)
                     this.startTime = moment(date, 'YYYY年MM月DD日').valueOf()
            },
            endDateHandleChange(date) {
                this.endTime = moment(date, 'YYYY年MM月DD日').valueOf()
            },
            reDateHandleChange(date) {
                console.log(moment(date))
                // this.updateVo.reTime = moment(date, 'YYYY年MM月DD日').valueOf()
                this.updateVo.reTime = new Date(moment(date, 'YYYY年MM月DD日').valueOf())
            },
            formatDate(date) {
                return moment(date).format('YYYY-MM-DD')
            },
            update(params) {
              if (params.row.tag) {
                   this.$Message.warning({
                    background: true,
                    content: '必须对应某天后才能修改数据',
                })
                  return
              }
              this.updateVo = params.row
              this.updateVo.reTime = new Date(params.row.reTime == null ? new Date().getTime() : params.row.reTime)
              console.log(this.updateVo)
              this.updateModel = true
            },
            updateOk() {
                this.updateVo.reTime = this.updateVo.reTime.getTime()
               updateReport(this.updateVo).then(res => {
                   console.log(res.data)
                   this.findReportList()
                   this.$Message.success('操作成功')
                   this.updateModel = false
               })
            },
              updateCancel() {
              console.log('updateCancel')
            },
            reTimeClear() {
                this.updateVo.reTime = new Date(0)
            },
            remove(row) {
              console.log(row)
              this.delModel = true
              this.delorId = row.orId
            },
            delOk() {
                console.log(this.delorId)
                delOrganizationVo(this.delorId).then(() => {
                    this.$Message.success('操作成功')
                    this.findReportList()
                })
            },
            delCancel(row) {
              console.log(row)
            },
            add() {
                this.addModel = true
            },
            addOk() {
                  // eslint-disable-next-line no-unused-vars
                  addOrganization(this.addVo).then(res => {
                     this.$Message.success('操作成功')
                     this.findReportList()
                     this.addVo = {}
                      this.addModel = false
                  })
                        },
            addCancel() {

                        },
  },
}
</script>

<style scoped>
.add{
    position: absolute;
    right: 40px;
}
.search-bt{
      position: absolute;
    right: 140px;
}
.query-c{
  height: 50px;
}
</style>